<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Enviar SMS
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loadingGet"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text
        v-if="apportionment"
        class="pt-4 text-center"
      >
        <v-row>
          <v-col>
            <p class="display-1">
              Confirme o envio dos SMS's referente ao <b>{{ apportionment.typeOfApportionment.description }}</b>
              do dia <b>{{ apportionment.date | date }}</b>
            </p>
          </v-col>
        </v-row>
        <validation-observer ref="observer">
          <v-row>
            <v-col class="text-left">
              <validation-provider
                v-slot="{ errors }"
                vid="typesOfContacts"
              >
                <p class="ma-0 subtitle-2">
                  Enviar para:
                </p>
                
                <v-row no-gutters>
                  <v-col md="6" v-for="typeOfContact in typesOfContacts" :key="typeOfContact.id">
                    <v-switch
                      v-model="fields.typesOfContacts"
                      class="mt-1"
                      color="primary"
                      hide-details="auto"
                      :label="typeOfContact.description"
                      :value="typeOfContact.id"
                      :error="errors.length > 0"
                    />
                  </v-col>
                </v-row>
                
                <div
                  v-if="errors.length > 0"
                  class="v-messages theme--light error--text mt-2"
                >
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue"
          outlined
          @click="showDialogConfirmation = true"
        >
          <v-icon
            left
            v-text="'mdi-send'"
          />
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>

    <dialog-confirm-send-sms
      v-model="showDialogConfirmation"
      :loading="loading"
      @send="send"
    />

  </v-dialog>
</template>

<script>
  import apportionmentsApi from '@/api/apportionments'
  import typesOfContactsApi from '@/api/types-of-contacts'

  export default {

    components: {
      DialogConfirmSendSms: () => import('@/components/dialog/DialogConfirmSendSms'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      apportionment: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        loadingGet: false,
        showDialogConfirmation: false,
        typesOfContacts: [],
        fields: {
          typesOfContacts: []
        }
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {
      this.loadTypesOfContacts()
    },

    methods: {
      async send () {
        this.loading = true
        this.$refs.observer.reset()

        try {
          const response = await apportionmentsApi.sendSms(this.apportionment.id, { ...this.fields })

          this.$snackbar.show({ color: 'success', message: response.data.message })
          this.showDialogConfirmation = false
          this.show = false
          this.$emit('send')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },

      async loadTypesOfContacts () {
        try {
          this.loadingGet = true

          const response = await typesOfContactsApi.list({
            limit: 1000,
            offset: 0,
          })

          this.typesOfContacts = response.data.typesOfContacts
          this.fields.typesOfContacts = [1]
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingGet = false
        }
      },

    },

  }
</script>
